import React, { PureComponent } from 'react';
import abc from '../../assets/images/woman.png';
import man from '../../assets/images/man.png';
import Carousel from 'react-grid-carousel'

const testimonials = [
  {
    id: 1,
    name: 'Dr. Mrs Atinuke',
    role: 'Proprietress, Star De Laville International School Abuja',
    message: 'From my observations, Cobena Schools is organised, neat, utilizes resources, has unique concepts and utilizes resources. ',
    image: abc, // Replace with actual image URLs
  },
  {
    id: 2,
    name: 'Mr Adejoh',
    role: 'Parent',
    message: 'We want to appreciate you and your wonderful staff for the amazing efforts and time put into the education of our son Charles Ojochide. His excellent growth and development is testament to your expertise and commitment. Thank with profound regards to to your staff',
    image: man,
  },
  {
    id: 3,
    name: 'Mrs Ekpo',
    role: 'Parent',
    message: 'The other day my husband and I were just analyzing cobena and we realize it\'s basically out of passion you do what you do. Nathan enjoys going to school. I was even thinking to  keep him at home till he turns 2, but the way Nathan is excited leaving the house every morning.',
    image: abc,
  },
  {
    id: 4,
    name: 'Mr. Nzom',
    role: 'Parent',
    message: 'As Anozie’s parents, we say thank you for helping us see him through his first term in school. We look forward to the next academic session.',
    image: man,
  },
  {
    id: 5,
    name: 'Mrs Okuma',
    role: 'Parent',
    message: 'I want to say thank you ma’am with potty training. I can say she’s 95 percent done. I wore her diapers to sleep at night and this morning the diaper was dry',
    image: abc,
  },
  {
    id: 6,
    name: 'Mrs Okihie',
    role: 'Parent',
    message: 'Well done to you people for handling plenty at a time. My appreciation for the potty training and my kids always insist on washing their hands.',
    image: abc,
  },
  {
    id: 7,
    name: 'Mrs Idakwo',
    role: 'Parent',
    message: 'Wow wow wow 💃 my girl is doing well I\’m glad 🙂',
    image: abc,
  },
];

class Testimonials extends PureComponent {
  render(){
    return(
      <div className="container mt-4" style={{ position: 'relative', top: 45 }}>
      <h3 className="text-center text-dark font-weight-bold">What Parents Are Saying</h3>
       {/*<Carousel cols={3} rows={1} gap={5} loop>
      <Carousel.Item>
    <figure class="snip1390">
    <img src={abc} alt="profile-sample3" class="profile" />
    <figcaption>
      <h2 className="text-light">Dr. Mrs Atinuke</h2>
      <h5 className="text-light">Proprietress, Star De Laville International School Abuja</h5>
      <blockquote>From my observations, Cobena Schools has the following unique qualities:
      <ol>
      <li>Organised</li>
      <li>Mastery of resources</li>
      <li>Unique Concept</li>
      <li>Uncommon ideas</li>
      <li>Neatness</li>
      <li>Top-notch teachers' responsiveness </li>
      </ol>
      </blockquote>
    </figcaption>
  </figure>
      </Carousel.Item>
      <Carousel.Item>
      <figure class="snip1390">
      <img src={man} alt="profile-sample3" class="profile" />
      <figcaption>
      <h2 className="text-light">Mr Adejoh</h2>
      <blockquote>
      We want to acknowledge & appreciate you and your wonderful staff for the amazing efforts and time put into the education of our son Charles Ojochide. His excellent growth and development is testament to your expertise and commitment and we look forward to next term with an expectation of his further excellent progress.
      Thank with profound regards to to your staff .
      </blockquote>
      </figcaption>
      </figure>
      </Carousel.Item>
      <Carousel.Item>
      <figure class="snip1390">
    <img src={abc} alt="profile-sample3" class="profile" />
    <figcaption>
      <h2 className="text-light">Mrs Ekpo</h2>
      <blockquote>
      The other day my husband and I were just analyzing cobena and we realize it's basically out of passion you do what you do. Nathan enjoys going to school. I was even thinking to  keep him at home till he turns 2, but the way Nathan is excited leaving the house every morning.
      </blockquote>
    </figcaption>
    </figure>
      </Carousel.Item>

      <Carousel.Item>
      <figure class="snip1390">
      <img src={man} alt="profile-sample3" class="profile" />
      <figcaption>
      <h2 className="text-light">Mr Nzom</h2>
      <blockquote>
      As Anozie’s parents, we say thank you for helping us see him through his first term in school. We look forward to the next academic session.
      </blockquote>
      </figcaption>
      </figure>
      </Carousel.Item>
      <Carousel.Item>
      <figure class="snip1390">
      <img src={abc} alt="profile-sample3" class="profile" />
      <figcaption>
      <h2 className="text-light">Mrs Idakwo</h2>
      <blockquote>
      Wow wow wow 💃 my girl is doing well
      I’m glad 🙂
      </blockquote>
      </figcaption>
      </figure>
      </Carousel.Item>
      <Carousel.Item>
      <figure class="snip1390">
      <img src={abc} alt="profile-sample3" class="profile" />
      <figcaption>
      <h2 className="text-light">Mrs Okuma</h2>
      <blockquote>
      I want to say thank you ma’am with potty training. I can say she’s 95 percent done. I wore her diapers to sleep at night and this morning the diaper was dry
      </blockquote>
      </figcaption>
      </figure>
      </Carousel.Item>
      <Carousel.Item>
      <figure class="snip1390">
      <img src={abc} alt="profile-sample3" class="profile" />
      <figcaption>
      <h2 className="text-light">Mrs Okihie</h2>
      <blockquote>
      Well done to you people for handling plenty at a time. My appreciation for the potty training and my kids always insist on washing their hands.
      </blockquote>
      </figcaption>
      </figure>
      </Carousel.Item>
    </Carousel> */}
   <div className="testimonial-container">
      {testimonials.map((testimonial) => (
        <div className="testimonial-card" key={testimonial.id}>
          <img
            className="testimonial-image"
            src={testimonial.image}
            alt={`Photo of ${testimonial.name}`}
          />
          <p className="testimonial-message">“{testimonial.message}”</p>
          <h3 className="testimonial-name">{testimonial.name}</h3>
          <p className="testimonial-role">{testimonial.role}</p>
        </div>
      ))}
    </div>

  </div>
    )
  }
}

export default Testimonials;
